<template>
	<div class="container content-themes">
		<div class="page-sub-box clearfix ui-glid-box top-box">
			<div class="fl per50">
				<table class="table_form line-bin">
					<caption>
						<strong>기본 정보</strong>
					</caption>
					<colgroup>
						<col style="width:50%">
						<col style="width:50%">
					</colgroup>
					<thead class="sub_title_txt">
						<tr>
							<td colspan="2">
								<h2>기본 정보</h2>
							</td>
						</tr>
					</thead>
					<tbody>
						<tr>
							<th scope="row">
								<label for="label5">
									<span>사이트</span>
									<span class="icon_require">필수항목</span>
								</label>
							</th>
							<td class="clearfix">
								<DxSelectBox
									v-model="actionData.siteId"
									width="300px"
									placeholder="사이트 선택"
									:items="infoPushSiteList"
									display-expr="siteNm"
									value-expr="siteId"
									:styling-mode="stylingMode"
									:disabled="actionData.id ? true : false"
								/>
							</td>
						</tr>
						<tr>
							<th scope="row">
								<label for="label5">
									<span>서비스 코드</span>
									<span class="icon_require">필수항목</span>
								</label>
							</th>
							<td class="clearfix">
								<DxTextBox
									v-model="actionData.svcCode"
									placeholder="서비스 코드"
									width="300px"
									:styling-mode="stylingMode"
									class="mar_ri10"
								>
								</DxTextBox>
							</td>
						</tr>
						<tr>
							<th scope="row">
								<label for="label5">
									<span>설명</span>
									<span class="icon_require">필수항목</span>
								</label>
							</th>
							<td class="clearfix">
								<DxTextBox
									v-model="actionData.description"
									placeholder="설명"
									width="300px"
									:styling-mode="stylingMode"
									class="mar_ri10"
								>
								</DxTextBox>
							</td>
						</tr>
						<tr>
							<th scope="row">
								<label for="label5">
									<span>사용여부</span>
									<span class="icon_require">필수항목</span>
								</label>
							</th>
							<td>
								<DxSwitch v-model="viewCd"/>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="fl per50" style="padding-top: 51px">
				<table class="table_form line-bin">
					<colgroup>
						<col style="width:50%">
						<col style="width:50%">
					</colgroup>
					<tbody>
					<tr>
						<th scope="row">
							<label for="label5">
								<span>IN/OUT</span>
								<span class="icon_require">필수항목</span>
							</label>
						</th>
						<td class="clearfix">
							<DxSelectBox
								v-model="actionData.inoutCd"
								width="300px"
								placeholder="IN/OUT 선택"
								:items="this.$_getCode('infopush_inout')"
								display-expr="codeNm"
								value-expr="id"
								:styling-mode="stylingMode"
							/>
						</td>
					</tr>
					<tr>
						<th scope="row">
							<label for="label5">
								<span>Event</span>
								<span class="icon_require">필수항목</span>
							</label>
						</th>
						<td class="clearfix">
							<DxSelectBox
								v-model="actionData.eventCd"
								width="300px"
								placeholder="Event 선택"
								:items="this.$_getCode('infopush_event')"
								display-expr="codeNm"
								value-expr="id"
								:styling-mode="stylingMode"
							/>
						</td>
					</tr>
					<tr>
						<th scope="row">
							<label for="label5">
								<span>순서</span>
								<span class="icon_require">필수항목</span>
							</label>
						</th>
						<td class="clearfix">
							<DxNumberBox
								v-model="actionData.actionOrd"
								placeholder="순서"
								width="300px"
								:styling-mode="stylingMode"
								class="mar_ri10"
							>
							</DxNumberBox>
						</td>
					</tr>
					</tbody>
				</table>
			</div>
		</div>
		<div class="line_bottom_1px"></div>

		<div class="page-sub-box clearfix ui-glid-box top-box">
			<div class="fl per50">
				<table class="table_form line-bin">
					<caption>
						<strong>프롤로그 관리</strong>
					</caption>
					<colgroup>
						<col style="width:50%">
						<col style="width:50%">
					</colgroup>
					<thead class="sub_title_txt">
					<tr>
						<td colspan="2">
							<h2>프롤로그 관리</h2>
						</td>
					</tr>
					</thead>
					<tbody>
					<tr>
						<th scope="row">
							<label for="label5">
								<span>프롤로그 동작</span>
								<span class="icon_require">필수항목</span>
							</label>
						</th>
						<td class="clearfix">
							<DxSelectBox
								v-model="actionData.prologueCd"
								width="300px"
								placeholder="프롤로그 동작 선택"
								:items="this.$_getCode('infopush_prologue')"
								display-expr="codeNm"
								value-expr="id"
								:styling-mode="stylingMode"
							/>
						</td>
					</tr>
					<tr>
						<th scope="row">
							<label for="label5">
								<span>프롤로그 이미지</span>
								<span class="icon_require">필수항목</span>
							</label>
						</th>
						<td class="clearfix">
							<DxSelectBox
								v-model="actionData.prologueImgId"
								width="300px"
								placeholder="이미지 선택"
								:items="this.imageList"
								display-expr="description"
								value-expr="id"
								:styling-mode="stylingMode"
							/>
						</td>
					</tr>
					<tr v-if="selectedPrologue.image">
						<th scope="row">
							<label for="label5">
								<!--<span>프롤로그 이미지</span>-->
							</label>
						</th>
						<td class="clearfix">
							<img
								v-if="prologueImageSource"
								:src="prologueImageSource"
								style="max-height: 150px; max-width: 300px"
							>
						</td>
					</tr>
					<tr v-if="selectedPrologue.url">
						<th scope="row">
							<label for="label5">
								<span>URL</span>
							</label>
						</th>
						<td class="clearfix">
							{{ selectedPrologue.url }}
						</td>
					</tr>
					<tr v-if="selectedPrologue.description">
						<th scope="row">
							<label for="label5">
								<span>설명</span>
							</label>
						</th>
						<td class="clearfix">
							{{ selectedPrologue.description }}
						</td>
					</tr>
					<tr v-if="selectedPrologue.id">
						<th scope="row">
							<label for="label5">
								<span>텍스트 사용여부</span>
							</label>
						</th>
						<td class="clearfix">
							{{ (selectedPrologue.textId1 || selectedPrologue.textId2 || selectedPrologue.textId3) ? '사용' : '미사용' }}
						</td>
					</tr>
					</tbody>
				</table>
			</div>
			<div class="fl per50">
				<table class="table_form line-bin">
					<caption>
						<strong>에필로그 관리</strong>
					</caption>
					<colgroup>
						<col style="width:50%">
						<col style="width:50%">
					</colgroup>
					<thead class="sub_title_txt">
						<tr>
							<td colspan="2">
								<h2>에필로그 관리</h2>
							</td>
						</tr>
					</thead>
					<tbody>
					<tr>
						<th scope="row">
							<label for="label5">
								<span>에필로그 동작</span>
								<span class="icon_require">필수항목</span>
							</label>
						</th>
						<td class="clearfix">
							<DxSelectBox
								v-model="actionData.epilogueCd"
								width="300px"
								placeholder="에필로그 동작 선택"
								:items="this.$_getCode('infopush_epilogue')"
								display-expr="codeNm"
								value-expr="id"
								:styling-mode="stylingMode"
							/>
						</td>
					</tr>
					<tr>
						<th scope="row">
							<label for="label5">
								<span>에필로그 이미지</span>
								<span class="icon_require">필수항목</span>
							</label>
						</th>
						<td class="clearfix">
							<DxSelectBox
								v-model="actionData.epilogueImgId"
								width="300px"
								placeholder="이미지 선택"
								:items="this.imageList"
								display-expr="description"
								value-expr="id"
								:styling-mode="stylingMode"
							/>
						</td>
					</tr>
					<tr v-if="selectedEpilogue.image">
						<th scope="row"></th>
						<td class="clearfix">
							<img
								v-if="epilogueImageSource"
								:src="epilogueImageSource"
								style="max-height: 150px; max-width: 300px"
							>
						</td>
					</tr>
					<tr v-if="selectedEpilogue.url">
						<th scope="row">
							<label for="label5">
								<span>URL</span>
							</label>
						</th>
						<td class="clearfix">
							{{ selectedEpilogue.url }}
						</td>
					</tr>
					<tr v-if="selectedEpilogue.description">
						<th scope="row">
							<label for="label5">
								<span>설명</span>
							</label>
						</th>
						<td class="clearfix">
							{{ selectedEpilogue.description }}
						</td>
					</tr>
					<tr v-if="selectedEpilogue.id">
						<th scope="row">
							<label for="label5">
								<span>텍스트 사용여부</span>
							</label>
						</th>
						<td class="clearfix">
							{{ (selectedEpilogue.textId1 || selectedEpilogue.textId2 || selectedEpilogue.textId3) ? '사용' : '미사용' }}
						</td>
					</tr>
					</tbody>
				</table>
			</div>
		</div>
		<section class="terms bottom-btn-box">
			<div class="page-sub-box">
				<div class="bottom-btn-wrap">
					<DxButton text="저 장" :width="120" :height="40" class="default filled txt_S medium" :onClick="() => onSave()" />
					<DxButton text="취 소" :width="120" :height="40" class="white filled txt_S medium" :onClick="() => $_goPrePage()" />
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import { DxSelectBox } from 'devextreme-vue/select-box';
import { DxTextBox } from 'devextreme-vue/text-box';
import { DxNumberBox } from 'devextreme-vue/number-box';
import { DxSwitch } from 'devextreme-vue/switch';
import { DxButton } from 'devextreme-vue/button';
import { isSuccess } from '@/plugins/common-lib';

export default {
	components: { DxButton, DxSwitch, DxTextBox, DxSelectBox, DxNumberBox },
	props: {
		sendData: {
			type: Object
		}
	},
	watch: {
		'actionData.siteId': function(newVal) {
			this.getImageList(newVal)
		},
		'actionData.prologueImgId': function(newVal) {
			if(newVal) {
				this.selectedPrologue = this.imageList.find((image) => image.id === newVal);
			} else {
				this.selectedPrologue = {};
			}
		},
		'actionData.epilogueImgId': function(newVal) {
			if(newVal) {
				this.selectedEpilogue = this.imageList.find((image) => image.id === newVal);
			} else {
				this.selectedEpilogue = {};
			}
		},
		'selectedPrologue.image': function(newVal) {
			if(newVal) {
				this.prologueImageSource = this.$_getAttachFileURL(null, newVal);
			}
		},
		'selectedEpilogue.image': function(newVal) {
			if(newVal) {
				this.epilogueImageSource = this.$_getAttachFileURL(null, newVal);
			}
		},
	},
	computed: {},
	data() {
		return {
			infoPushSiteList: [],
			stylingMode: 'outlined',
			actionData: {
				prologueImgId: null,
				epilogueImgId: null
			},
			viewCd: true,
			imageList: [],
			selectedPrologue: {},
			selectedEpilogue: {},
			prologueImageSource: null,
			epilogueImageSource: null
		}
	},
	methods: {
		async onSave() {
			if(!this.actionData.siteId) {
				this.$_Msg("사이트 선택은 필수 항목입니다.");
				return;
			} else if(!this.actionData.svcCode) {
				this.$_Msg("서비스 코드는 필수 항목입니다.");
				return;
			} else if(!this.actionData.description) {
				this.$_Msg("설명은 필수 항목입니다.");
				return;
			} else if(!this.actionData.inoutCd) {
				this.$_Msg("IN/OUT은 필수 항목입니다.");
				return;
			} else if(!this.actionData.eventCd) {
				this.$_Msg("Event는 필수 항목입니다.");
				return;
			} else if(!this.actionData.actionOrd) {
				this.$_Msg("순서는 필수 항목입니다.");
				return;
			} else if(!this.actionData.prologueCd) {
				this.$_Msg("프롤로그 동작은 필수 항목입니다.");
				return;
			} else if(!this.actionData.epilogueCd) {
				this.$_Msg("에필로그 동작은 필수 항목입니다.");
				return;
			}

			const prologue = this.$_getCode('infopush_prologue').find((item) => item.id === this.actionData.prologueCd);
			const epilogue = this.$_getCode('infopush_epilogue').find((item) => item.id === this.actionData.epilogueCd);
			if(prologue?.codeValue === 'NONE' && epilogue?.codeValue === 'NONE') {
				this.$_Msg("동작 유형 선택이 잘못되었습니다.");
				return;
			}

			this.actionData.viewCd = this.viewCd ? this.$_getUsedCode.id : this.$_getUnUsedCode.id;
			const payload = {
				actionname: 'INFOPUSH_ACTION_UPDATE',
				data: {
					data: [this.actionData]
				}
			}

			const res = await this.CALL_API(payload);
			if(isSuccess(res)) {
				await this.$_Msg("저장되었습니다.");
				this.$_goPrePage();
			}
		},
		async getInfoPushSiteList() {
			const payload = {
				actionname: 'INFOPUSH_SITE_LIST_ALL',
				data: {
					params: {
						viewCd: this.$_getUsedCode.id,
						sort: '+siteOrd'
					}
				}
			}
			const res = await this.CALL_API(payload);
			if(isSuccess(res)) {
				if(res.data.data.length) {
					this.infoPushSiteList = res.data.data
				} else {
					this.infoPushSiteList = [];
				}
			}
		},
		/** @description : 라이프사이클 created시 호출되는 메서드 */
		async createdData() {
			await this.getInfoPushSiteList();
			await this.getImageList();
			if(this.$store.getters.getDetailParams?.sendData) {
				this.actionData = { ...this.$store.getters.getDetailParams.sendData }
			}
		},
		async getImageList(siteId) {
			const params = {
				viewCd: this.$_getUsedCode.id,
				sort: '+imgOrd'
			};

			if(siteId) {
				params.siteId = siteId;
			}

			const payload = {
				actionname: 'INFOPUSH_IMG_LIST_ALL',
				data: {
					params
				}
			};

			const res = await this.CALL_API(payload);
			if(isSuccess(res)) {
				this.imageList = [{ id: null, description: '미사용' }, ...res.data.data];
			}
		},
	},
	created() {
		this.createdData();
	},
	mounted() {},
	destroyed() {},
};
</script>

<style scoped>
.ui-glid-box > div.fl {
    border-right: 0;
}

.top-box {
    height: auto;
}
.top-box > div {
    height: auto;
}

.table_form td > div {
    display: inline-block;
    vertical-align: middle;
}
</style>
